import React from 'react'
import { Link } from 'react-router-dom'
import BGImg from "../../../assets/images/bread_crumb_bg.png"
import BGImg1 from "../../../assets/images/bread_crumb_bg_one.png"
import BGImg2 from "../../../assets/images/bread_crumb_bg_two.png"
import Shape1 from "../../../assets/images/banner-shape1.png"
import Shape2 from "../../../assets/images/banner-shape2.png"
import Shape3 from "../../../assets/images/banner-shape3.png"
import Shapev from "../../../assets/images/banner-shapev.png"
import Shapev2 from "../../../assets/images/banner-shapev2.png"
import Shapev3 from "../../../assets/images/banner-shapev3.png"


import Ago from '../Menblog/Ago'
import Last from '../Menblog/Last'

const Main = ({ brdcum, bgimg }) => {
    return (
        <>
            {brdcum.b1 &&
                <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
                    <div className="container">
                        <span className="banner_shape1"> <img src={Shape1}alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Shape2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Shape3} alt="image" /> </span>

                        <div className="bred_text">
                            <h1>Latest blog post</h1>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>»</span></li>
                                <li><Link to="/blog">Blog list</Link></li>
                            </ul>
                            <div className="search_bar">
                                <form action="">
                                    <div className="form-group">
                                        <input type="text" placeholder="Search here" className="form-control" />
                                        <button className="btn" type="submit"><i className="icofont-search-1"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {brdcum.b2 &&
                <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg1})` }}>
                    <div className="container">
                        <span className="banner_shape1"> <img src={Shape1} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Shape2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Shape3} alt="image" /> </span>

                        <div className="bred_text">
                            <h1>Latest blog post</h1>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>»</span></li>
                                <li><Link to="/blog">Blog list</Link></li>
                            </ul>
                            <div className="search_bar">
                                <form action="">
                                    <div className="form-group">
                                        <input type="text" placeholder="Search here" className="form-control" />
                                        <button className="btn" type="submit"><i className="icofont-search-1"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {brdcum.b5 &&
                <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
                    <div className="container">
                        <span className="banner_shape1"> <img src={Shape1} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Shape2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Shape3} alt="image" /> </span>

                        <div className="bred_text">
                            <h1>Latest blog post</h1>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>»</span></li>
                                <li><Link to="/blog">Blog list</Link></li>
                            </ul>
                            <div className="search_bar">
                                <form action="">
                                    <div className="form-group">
                                        <input type="text" placeholder="Search here" className="form-control" />
                                        <button className="btn" type="submit"><i className="icofont-search-1"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {brdcum.b3 &&
                <div className="bred_crumb  gredient-bg">
                    <div className="container">
                        <span className="banner_shape1"> <img src={Shapev} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Shapev2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Shapev3} alt="image" /> </span>

                        <div className="bred_text">
                            <h1>Latest blog post</h1>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>»</span></li>
                                <li><Link to="/blog">Blog list</Link></li>
                            </ul>
                            <div className="search_bar">
                                <form action="">
                                    <div className="form-group">
                                        <input type="text" placeholder="Search here" className="form-control" />
                                        <button className="btn" type="submit"><i className="icofont-search-1"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {brdcum.b4 &&
                <div className="bred_crumb video" style={{ backgroundImage: `url(${BGImg2})` }}>
                    <div className="container">
                        <span className="banner_shape1"> <img src={Shape1} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Shape2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Shape3} alt="image" /> </span>

                        <div className="bred_text">
                            <h1>Latest blog post</h1>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>»</span></li>
                                <li><Link to="/blog">Blog list</Link></li>
                            </ul>
                            <div className="search_bar">
                                <form action="">
                                    <div className="form-group">
                                        <input type="text" placeholder="Search here" className="form-control" />
                                        <button className="btn" type="submit"><i className="icofont-search-1"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Ago />
            <Last />
        </>
    )
}

export default Main