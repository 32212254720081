import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import img1 from '../../../assets/images/standard.png'
import img2 from '../../../assets/images/club_1.png'
import img3 from '../../../assets/images/club_2.png'
import Starded1 from "../../../assets/images/standard-one.png"
import Unlimited1 from "../../../assets/images/unlimited-one.png"
import Premium1 from "../../../assets/images/premium-one.png"

const Main = ({ gredient, video }) => {

    const [tog, setTog] = useState(true)

    return (
        <>
            {gredient ?
                {/* <section className="row_am pricing_section gredient-pricing" id="pricing">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                    <h2>Clear & simple <span>pricing</span></h2>
                    <p className="gredient-p">Lorem Ipsum is simply dummy text of the printing and typese tting <br/> indus orem Ipsum has beenthe
                        standard dummy.</p>
                    </div>
                    <div className="toggle_block" data-aos="fade-up" data-aos-duration="1500">
                        <span className={`month ${tog ? 'active' : ''}`}>Monthly</span>
                        <div className="tog_block">
                            <span className={`tog_btn ${tog && "month_active"}`} onClick = {() => setTog(tog === true ? false : true)}></span>
                        </div>
                        <span className="years">Yearly</span>
                        <span className="offer">50% off</span>
                    </div>

                    <div className={`pricing_pannel monthly_plan ${tog ? '' : 'active'}`}>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="pricing_block">
                                    <div className="icon">
                                    <img src={Starded1} alt="image" />
                                    </div>
                                    <div className="pkg_name">
                                    <h3>Standard</h3>
                                    <span>For the basics</span>
                                    </div>
                                    <span className="price">$15</span>
                                    <ul className="benifits">
                                    <li>
                                        <p>Up to 5 Website</p>
                                    </li>
                                    <li>
                                        <p>50 GB disk space</p>
                                    </li>
                                    <li>
                                        <p>10 Customize sub pages</p>
                                    </li>
                                    <li>
                                        <p>2 Domains access</p>
                                    </li>
                                    <li>
                                        <p>Support on request</p>
                                    </li>
                                    </ul>
                                    <Link to="/faq" className="btn white_btn">BUY NOW</Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pricing_block highlited_block">
                                    <div className="icon">
                                    <img src={Unlimited1} alt="image"/>
                                    </div>
                                    <div className="pkg_name">
                                    <h3>Unlimited</h3>
                                    <span>For the professionals</span>
                                    </div>
                                    <span className="price">$99</span>
                                    <ul className="benifits">
                                    <li>
                                        <p>Unlimited Website</p>
                                    </li>
                                    <li>
                                        <p>200 GB disk space</p>
                                    </li>
                                    <li>
                                        <p>20 Customize sub pages</p>
                                    </li>
                                    <li>
                                        <p>10 Domains access</p>
                                    </li>
                                    <li>
                                        <p>24/7 Customer support</p>
                                    </li>
                                    </ul>
                                    <Link to="/faq" className="btn white_btn">BUY NOW</Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pricing_block">
                                    <div className="icon">
                                    <img src={Premium1} alt="image" />
                                    </div>
                                    <div className="pkg_name">
                                    <h3>Premium</h3>
                                    <span>For small team</span>
                                    </div>
                                    <span className="price">$55</span>
                                    <ul className="benifits">
                                    <li>
                                        <p>Up to 10 Website</p>
                                    </li>
                                    <li>
                                        <p>100 GB disk space</p>
                                    </li>
                                    <li>
                                        <p>15 Customize sub pages</p>
                                    </li>
                                    <li>
                                        <p>4 Domains access</p>
                                    </li>
                                    <li>
                                        <p>24/7 Customer support</p>
                                    </li>
                                    </ul>
                                    <Link to="/faq" className="btn white_btn">BUY NOW</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`pricing_pannel yearly_plan ${tog ? 'active' : ''}`} >
                        <div className="row">
                            <div className="col-md-4">
                                <div className="pricing_block">
                                    <div className="icon">
                                    <img src={img1} alt="image" />
                                    </div>
                                    <div className="pkg_name">
                                    <h3>Standard</h3>
                                    <span>For the basics</span>
                                    </div>
                                    <span className="price">$150</span>
                                    <ul className="benifits">
                                    <li>
                                        <p>Up to 10 Website</p>
                                    </li>
                                    <li>
                                        <p>100 GB disk space</p>
                                    </li>
                                    <li>
                                        <p>25 Customize sub pages</p>
                                    </li>
                                    <li>
                                        <p>4 Domains access</p>
                                    </li>
                                    <li>
                                        <p>Support on request</p>
                                    </li>
                                    </ul>
                                    <Link to="/faq" className="btn white_btn">BUY NOW</Link>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="pricing_block highlited_block">
                                    <div className="icon">
                                    <img src={img2} alt="image" />
                                    </div>
                                    <div className="pkg_name">
                                    <h3>Unlimited</h3>
                                    <span>For the professionals</span>
                                    </div>
                                    <span className="price">$999</span>
                                    <ul className="benifits">
                                    <li>
                                        <p>Unlimited Website</p>
                                    </li>
                                    <li>
                                        <p>400 GB disk space</p>
                                    </li>
                                    <li>
                                        <p>40 Customize sub pages</p>
                                    </li>
                                    <li>
                                        <p>20 Domains access</p>
                                    </li>
                                    <li>
                                        <p>24/7 Customer support</p>
                                    </li>
                                    </ul>
                                    <Link to="/faq" className="btn white_btn">BUY NOW</Link>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="pricing_block">
                                    <div className="icon">
                                    <img src={img3} alt="image" />
                                    </div>
                                    <div className="pkg_name">
                                    <h3>Premium</h3>
                                    <span>For small team</span>
                                    </div>
                                    <span className="price">$550</span>
                                    <ul className="benifits">
                                    <li>
                                        <p>Up to 20 Website</p>
                                    </li>
                                    <li>
                                        <p>200 GB disk space</p>
                                    </li>
                                    <li>
                                        <p>25 Customize sub pages</p>
                                    </li>
                                    <li>
                                        <p>8 Domains access</p>
                                    </li>
                                    <li>
                                        <p>24/7 Customer support</p>
                                    </li>
                                    </ul>
                                    <Link to="/faq" className="btn white_btn">BUY NOW</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="contact_text" data-aos="fade-up" data-aos-duration="1500">Not sure what to choose ? <Link to="/contact">Contact us</Link></p>
                </div>
            </section> */}
                :
                <section className="row_am pricing_section" id="pricing">
                    <div className="container">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                            <h2>Clear & simple <span>pricing</span></h2>
                            {/* {tog &&
                                <div>
                                    <br />
                                    <p>
                                        Be one of the first <b>50</b> clubs to join our <b>Ambassadors</b> program and help us shape Zzerb!
                                        Join our <b>Ambassadors</b> program and let's shape the best <b>win-win</b> deal together! We have <b>50 spots</b> available.
                                    </p>
                                    <ul className="list">
                                        <li data-aos="fade-up" data-aos-duration="1500">
                                            <i className="icofont-verification-check"></i> Access to new features before anyone else.
                                        </li>
                                        <li data-aos="fade-up" data-aos-duration="1500">
                                            <i className="icofont-verification-check"></i> Join our quarterly prospect meetings and share your insights
                                        </li>
                                        <li data-aos="fade-up" data-aos-duration="1500">
                                            <i className="icofont-verification-check"></i> Expect referral bonuses and additional benefits along the way.
                                        </li>
                                    </ul>
                                    <br />
                                </div>
                                // <p>
                                //     Join the exclusive ranks of our first 50 clubs as Ambassadors and shape the best win-win deal together!
                                //     <br/>
                                //     Get first access to all new features before anyone else.
                                //     <br/>
                                //     Join our quarterly prospect meetings 
                                //     <br/>
                                //     Expect referral bonuses and additional benefits along the way.
                                // </p>
                            } */}
                        </div>
                        <div className="toggle_block" data-aos="fade-up" data-aos-duration="1500">
                            <span className={`month ${!tog ? 'active' : ''}`}>For Users</span>
                            <div className="tog_block" onClick={() => setTog(tog === true ? false : true)}>
                                <span className={`tog_btn ${tog && "month_active"}`}></span>
                            </div>
                            <span className={`years ${tog ? 'active' : ''}`}>For Clubs</span>
                            {/* <span className="offer">50% off</span> */}
                        </div>

                        <div className={`pricing_pannel monthly_plan ${tog ? '' : 'active'}`}>
                            <div className="row" style={{ justifyContent: 'center' }}>
                                {/* <div className="col-md-4">
                                <div className="pricing_block">
                                    <div className="icon">
                                    { video ? <img src={Starded1} alt="image" /> : <img src={img1} alt="image" /> }
                                    </div>
                                    <div className="pkg_name">
                                    <h3>Standard</h3>
                                    <span>For the basics</span>
                                    </div>
                                    <span className="price">$15</span>
                                    <ul className="benifits">
                                    <li>
                                        <p>Up to 5 Website</p>
                                    </li>
                                    <li>
                                        <p>50 GB disk space</p>
                                    </li>
                                    <li>
                                        <p>10 Customize sub pages</p>
                                    </li>
                                    <li>
                                        <p>2 Domains access</p>
                                    </li>
                                    <li>
                                        <p>Support on request</p>
                                    </li>
                                    </ul>
                                    <Link to="/faq" className="btn white_btn">BUY NOW</Link>
                                </div>
                            </div> */}
                                <div className="col-md-4">
                                    <div className="pricing_block highlited_block">
                                        <div className="icon">
                                            {video ? <img src={Unlimited1} alt="image" /> : <img src={img2} alt="image" />}
                                        </div>
                                        <div className="pkg_name">
                                            <h3>Forever</h3>
                                            {/* <span>For the professionals</span> */}
                                        </div>
                                        <span className="price">FREE</span>
                                        {/* <p className="benifits">Our services will always be available for free for our users!</p> */}
                                        <ul className="benifits" style={{}}>
                                            <li>
                                                <p>Zzerb will always be free for members! Explore clubs, browse strains, and schedule pick-ups without any cost.</p>
                                            </li>
                                        </ul>
                                        <a target='_blank' href="https://test-zzerb-mobile.web.app" className="btn white_btn">Join the Community</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`pricing_pannel yearly_plan ${tog ? 'active' : ''}`} >
                            <div className="row" style={{ justifyContent: 'center' }}>
                                <div className="col-md-4">
                                    <div className="pricing_block">
                                        <div className="icon">
                                            {video ? <img src={Starded1} alt="image" /> : <img src={img1} alt="image" />}
                                        </div>
                                        <div className="pkg_name">
                                            <h3>Free Trial</h3>
                                            <span>1st month <br /> 3 months for Society Members</span>
                                        </div>
                                        <span className="price">FREE</span>
                                        <ul className="benifits">
                                            <li>
                                                <p>Experience all our features at no cost for a full month—or six months if you join you are part of the Zzerb Society. We want you to see the impact Zzerb can make before you commit.</p>
                                            </li>
                                        </ul>
                                        <a target='_blank' href="https://test-zzerb.web.app/" className="btn white_btn">Start for Free</a>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="pricing_block highlited_block">
                                        <div className="icon">
                                            {video ? <img src={Unlimited1} alt="image" /> : <img src={img3} alt="image" />}
                                        </div>
                                        <div className="pkg_name">
                                            <h3>Standard</h3>
                                            <span>After the free trial</span>
                                        </div>
                                        <p className="price" style={{ marginBottom: 0 }}>50€<span style={{}}>join fee</span></p>
                                        <p className="price" style={{ margin: 0 }}>+0.85€<span>/member</span></p>
                                        <ul className="benifits">
                                            <li>
                                                <p><b>per month + VAT</b></p>
                                            </li>
                                            <li>
                                                <p>After your free trial, keep your club growing with our standard plan. Pay a one-time fee of 50€ and just 0.85€ per member per month. Simple, fair, and cost-effective.</p>
                                            </li>
                                        </ul>
                                        <a target='_blank' href="https://test-zzerb.web.app/" className="btn white_btn">Start for Free</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <p className="contact_text" data-aos="fade-up" data-aos-duration="1500">Not sure what to choose ? <Link to="/contact">Contact us</Link></p> */}
                    </div>
                </section>
            }
        </>
    )
}

export default Main