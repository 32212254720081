import React from 'react'
import { Link } from 'react-router-dom'
import Blogdtls from "../../../assets/images/blod-detail.png"

function Ago() {
    return (
        <>
            <section className="row_am blog_list_main">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6" data-aos="fade-in" data-aos-duration="1500">
                            <div className="blog_img">
                                <img src={Blogdtls} alt="image" />
                                <span>20 min ago</span>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="blog_text">
                                <span className="choice_badge">EDITOR CHOICE</span>
                                <div className="section_title">
                                    <h2>Top rated app of the year!</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting in
                                        dustry lorem Ipsum has been the industrys standard dummy text ev
                                        er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                                    <Link to="/blog-single">READ MORE</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ago