import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom'
import Messageicon from "../../assets/images/contact_message_icon.png"
import Mailicon from "../../assets/images/mail_icon.png"
import Callicon from "../../assets/images/call_icon.png"
import Locationicon from "../../assets/images/location_icon.png"

function Leave() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_4jl0w0f',
                'template_i97j5m7',
                form.current, {
                publicKey: 'zFJny_AxPWmdO17AO',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    window.location.reload();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <>
            <section className="contact_page_section" id="contact">
                <div className="container">
                    <div className="contact_inner">
                        <div className="contact_form">
                            <div className="section_title">
                                <h2>Leave a <span>message</span></h2>
                                <p>Fill up form below, our team will get back soon</p>
                            </div>
                            <form onSubmit={sendEmail} ref={form}>
                                <div className="form-group">
                                    <input type="text" placeholder="Name" className="form-control" name='from_name' />
                                </div>
                                <div className="form-group">
                                    <input type="email" placeholder="Email" className="form-control" name='from_email' />
                                </div>
                                <div className="form-group">
                                    <input type="text" placeholder="Club Name" className="form-control" name='from_club_name' />
                                </div>
                                {/* <div className="form-group">
                                    <select className="form-control">
                                        <option value="">Country</option>
                                    </select>
                                </div> */}
                                <div className="form-group">
                                    <input type="text" placeholder="Phone" className="form-control" name='from_phone' />
                                </div>
                                <div className="form-group">
                                    <input type="text" placeholder="Website" className="form-control" name='from_website' />
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" placeholder="Your message" name='message'></textarea>
                                </div>
                                <div className="form-group term_check">
                                    <input type="checkbox" id="term" name='agree_newsletter' />
                                    <label htmlFor="term">I agree to receive emails, newsletters and promotional messages</label>
                                </div>
                                <div className="form-group mb-0">
                                    <button type="submit" className="btn puprple_btn">SEND MESSAGE</button>
                                </div>
                            </form>
                        </div>
                        {/* <div className="contact_info">
                            <div className="icon"><img src={Messageicon} alt="image" /></div>
                            <div className="section_title">
                                <h2>Have any <span>question?</span></h2>
                                <p>If you have any question about our product, service, payment or company, Visit our <Link to="/faq">FAQs page.</Link></p>
                            </div>
                            <Link to="/faq" className="btn puprple_btn">READ FAQ</Link>
                            <ul className="contact_info_list">
                                <li>
                                    <div className="img">
                                        <img src={Mailicon} alt="image" />
                                    </div>
                                    <div className="text">
                                        <span>Email Us</span>
                                        <Link to="#">example@gmail.com</Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="img">
                                        <img src={Callicon} alt="image" />
                                    </div>
                                    <div className="text">
                                        <span>Call Us</span>
                                        <Link to="#">+1 (888) 553-46-11</Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="img">
                                        <img src={Locationicon} alt="image" />
                                    </div>
                                    <div className="text">
                                        <span>Visit Us</span>
                                        <p>5687, Business Avenue, New York, USA 5687</p>
                                    </div>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Leave