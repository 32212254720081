import React from 'react'
import { Link } from 'react-router-dom'
import Step1 from "../../assets/images/step1.png"
import Step2 from "../../assets/images/step2.png"
import Step3 from "../../assets/images/step3.png"


function Steps() {
    return (
        <>
            <section className="row_am how_it_works video-work-two" id="how_it_work">
                <div className="container">
                    <div className="how_it_inner">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                            <h2>How it works - 3 easy steps</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br /> indus orem Ipsum has beenthe
                                standard dummy.</p>
                        </div>
                        <div className="step_block">
                            <div className="row ">
                                <div className="col-md-4 col-sm-12">
                                    <div className="step_box">
                                        <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                            <img src={Step1} alt="image" />
                                            <div className="step_number">
                                                <h3>01</h3>
                                            </div>
                                        </div>
                                        <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                            <h4>Download app</h4>
                                            <div className="app_icon">
                                                <Link to="#"><i className="icofont-brand-android-robot"></i></Link>
                                                <Link to="#"><i className="icofont-brand-apple"></i></Link>
                                                <Link to="#"><i className="icofont-brand-windows"></i></Link>
                                            </div>
                                            <p>Download App either for Windows, Mac or Android</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="step_box">
                                        <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                            <img src={Step2} alt="image" />
                                            <div className="step_number">
                                                <h3>02</h3>
                                            </div>
                                        </div>
                                        <div className="step_text step2" data-aos="fade-right" data-aos-duration="1500">
                                            <h4>Create account</h4>
                                            <span>14 days free trial</span>
                                            <p>Sign up free for App account. One account for all devices.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div className="step_box">
                                        <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                            <img src={Step3} alt="image" />
                                            <div className="step_number">
                                                <h3>03</h3>
                                            </div>
                                        </div>
                                        <div className="step_text step3" data-aos="fade-right" data-aos-duration="1500">
                                            <h4>It’s done, enjoy the app</h4>
                                            <span>Have any questions check our FAQs</span>
                                            <p>Get most amazing app experience, Explore and share the app</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Steps