import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../../Bredcrumb/Main'
import BGImg from "../../../assets/images/bread_crumb_bg.png"
import BGImg1 from "../../../assets/images/bread_crumb_bg_one.png"
import BGImg2 from "../../../assets/images/bread_crumb_bg_two.png"
import Review from '../Menblogsingle/Review'
import Comments from '../Menblogsingle/Comments'
import Leave from '../Menblogsingle/Leave'
import Latest from '../Menblogsingle/latest'

const Main = ({ brdcum }) => {

    return (
        <>

            {brdcum.b1 &&
                <Bredcrumb no={1} title="Blog details" tag="Blog single" bgimg={BGImg} />
            }

            {brdcum.b2 &&
                <Bredcrumb no={2} title="Blog details" tag="Blog single" bgimg={BGImg1} />
            }

            {brdcum.b5 &&
                <Bredcrumb no={5} title="Blog details" tag="Blog single" bgimg={BGImg} />
            }

            {brdcum.b3 &&
                <Bredcrumb no={3} title="Blog details" tag="Blog single" />
            }

            {brdcum.b4 &&
                <Bredcrumb no={4} title="Blog details" tag="Blog single" bgimg={BGImg2} />
            }

            <Review />
            <Comments />
            <Leave />
            <Latest />
        </>
    )
}

export default Main