import React from 'react'
import { Link } from 'react-router-dom'
import Blogdtls from "../../../assets/images/blog_detail_main.png"
import Blogsub1 from "../../../assets/images/blog_sub_01.png"
import Blogsub2 from "../../../assets/images/blog_sub_02.png"
import Icon from "../../../assets/images/quote_icon.png"
import BlogD1 from "../../../assets/images/blog_d02.png"

function Review() {
    return (
        <>
            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Review</span>
                            <span>45 min ago</span>
                        </div>
                        <div className="section_title">
                            <h2>Top rated app of the year!</h2>
                        </div>
                        <div className="main_img">
                            <img src={Blogdtls} alt="image" />
                        </div>
                        <div className="info">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic Lorem Ipsum is simply dummy text of the printing and typesettingindustry lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived.</p>
                            <p>Printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unnown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic Lorem Ipsum is simply dummy text of the printing and typesettingindustry lorem Ipsum has been the industrys centuries, but also the leap into electronic.</p>
                            <h3>Why we are best</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic.</p>
                            <ul>
                                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Lorem Ipsum is simply dummy text of the printing and typesetting in </p></li>
                                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Dustry lorem Ipsum has been the industrys standard dummy text ev er since the when</p></li>
                                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Unknown printer took a galley of type and scrambled it to make.</p></li>
                                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Type specimen book. It has survived not only five centuries, but also the leap into electronic.</p></li>
                                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Lorem Ipsum is simply dummy text of the printing.</p></li>
                                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Dustry lorem Ipsum has been the industrys standard dummy text ev er since.</p></li>
                                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Unknown printer took a galley of type and scrambled it to make.</p></li>
                                <li><p> <span className="icon"><i className="icofont-check-circled"></i></span> Type specimen book. It has survived not only.</p></li>
                            </ul>
                        </div>
                        <div className="two_img">
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={Blogsub1} alt="image" />
                                </div>
                                <div className="col-md-6">
                                    <img src={Blogsub2} alt="image" />
                                </div>
                            </div>
                        </div>
                        <div className="info">
                            <h3>Why we are best</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic Lorem Ipsum is simply dummy text of the printing and typesettingindustry lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived.</p>
                        </div>
                        <div className="quote_block">
                            <span className="q_icon"><img src={Icon} alt="image" /></span>
                            <h2>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy.</h2>
                            <p><span className="name">Mr. John Doe,</span> zzerb Inc</p>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic industry.</p>
                        <div className="blog_authore">
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={BlogD1} alt="image" />
                                </div>
                                <div className="text">
                                    <h3>By: John Dow</h3>
                                    <span>July 25, 2022</span>
                                </div>
                            </div>
                            <div className="social_media">
                                <ul>
                                    <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="blog_tags">
                            <ul>
                                <li className="tags"><p>Tags:</p></li>
                                <li><span>app,</span></li>
                                <li><span>rating,</span></li>
                                <li><span>development</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Review