import { Link } from 'react-router-dom'
import React from 'react'
import CountUp from 'react-countup';
import frame from '../../../assets/images/about-frame.png'
import screen from '../../../assets/images/about-screen-2.png'
import img1 from '../../../assets/images/download.png'
import img2 from '../../../assets/images/followers.png'
import img3 from '../../../assets/images/reviews.png'
import img4 from '../../../assets/images/countries.png'
import Downloadimg from "../../../assets/images/download-one.png"
import Followimg from "../../../assets/images/followers-one.png"
import Reviewimg from "../../../assets/images/reviews.png"
import Countriesimg from "../../../assets/images/countries-one.png"

const Main = ({ video, dark }) => {
    return (
        <>
            <section className="row_am about_app_section">
                <div className="container">
                    <div className="row" style={{ alignItems: 'center' }}>
                        <div className="col-lg-6">
                            <div className="about_img" data-aos="fade-in" data-aos-duration="1500" style={{ justifyContent: 'center' }}>
                                <div className="frame_img">
                                    <img className="moving_position_animatin" src={frame} alt="image" />
                                </div>
                                <div className="screen_img">
                                    <img className="moving_animation" src={screen} alt="image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2><span>Reverse Match</span> System</h2>
                                    {/* <p>
                                        Protect your club's privacy with our innovative <b>Reverse Match System.</b> Our users can reach out to you without exposing your club's identity.
                                    </p> */}
                                    <p>Connecting with new members without breaking the <br /> <b>Anti-Advertising Law</b> can be tough. Zzerb's unique system makes it easy.</p>
                                </div>
                                <ul className="list">
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <i className="icofont-verification-check"></i> 100% compliant with the law
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <i className="icofont-verification-check"></i> Protects your club's reputation
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <i className="icofont-verification-check"></i> Easy to use
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <i className="icofont-ban"></i> No public map pins—stay discreet
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <i className="icofont-ban"></i> Zero "under the radar" risks
                                    </li>
                                </ul>
                                {/* <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration="1500">
                                    <li>
                                        <div className="icon">
                                            {video ? <img src={Downloadimg} alt="image" /> : <img src={img1} alt="image" />}
                                        </div>
                                        <div className="text">
                                            <p><CountUp className="counter-value" data-count="17" start={0} end={17} duration={2.5}></CountUp><span>M+</span></p>
                                            <p>Download</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            {video ? <img src={Followimg} alt="image" /> : <img src={img2} alt="image" />}
                                        </div>
                                        <div className="text">
                                            <p><CountUp className="counter-value" data-count="08" start={0} end={8} duration={2.5}></CountUp><span>M+</span></p>
                                            <p>Followers</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            {video ? <img src={Reviewimg} alt="image" /> : <img src={img3} alt="image" />}
                                        </div>
                                        <div className="text">
                                            <p><CountUp className="counter-value" data-count="2300" start={0} end={2300} duration={2.5}></CountUp><span>+</span></p>
                                            <p>Reviews</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            {video ? <img src={Countriesimg} alt="image" /> : <img src={img4} alt="image" />}
                                        </div>
                                        <div className="text">
                                            <p><CountUp className="counter-value" data-count="150" start={0} end={150} duration={2.5}></CountUp><span>+</span></p>
                                            <p>Countries</p>
                                        </div>
                                    </li>
                                </ul> */}
                                <Link to="/contact" className="btn puprple_btn" data-aos="fade-in" data-aos-duration="1500">Schedule Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main