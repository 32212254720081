import React from 'react'
import Pricing from '../HomeMain/Pricing/Main'
import Bredcrumb from '../Bredcrumb/Main'
import BGImg from "../../assets/images/bread_crumb_bg.png"
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png"
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png"
import Top from '../Pages/Menpricing/Top'
import First from '../Pages/Menpricing/First'


const Main = ({ brdcum }) => {

    return (
        <>
            {brdcum.b1 &&
                <Bredcrumb no={1} title="Our Pricing" paragraph="We are committed to keeping our prices reasonable and transparent, ensuring a win-win collaboration for both clubs and users. At Zzerb, your success is our priority." tag="Pricing" bgimg={BGImg} />
            }

            {brdcum.b2 &&
                <Bredcrumb no={2} title="Our Pricing & Plans" paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting" tag="Our Pricing & Plans" bgimg={BGImg1} />
            }

            {brdcum.b5 &&
                <Bredcrumb no={5} title="Our Pricing & Plans" paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting" tag="Our Pricing & Plans" bgimg={BGImg} />
            }


            {brdcum.b3 &&
                <Bredcrumb no={3} title="Our Pricing & Plans" paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting" tag="Our Pricing & Plans" />
            }

            {brdcum.b4 &&
                <Bredcrumb sno={4} title="Our Pricing & Plans" paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting" tag="Our Pricing & Plans" bgimg={BGImg2} />
            }

            <Pricing />
            {/* <Top /> */}
            {/* <First /> */}

        </>
    )
}

export default Main