import React from 'react'
import Blog01 from "../../../assets/images/blog_d01.png"
import Blog02 from "../../../assets/images/blog_d02.png"
import Blog03 from "../../../assets/images/blog_d03.png"


function Comments() {
  return (
    <>
        <section className="row_am comment_section">
                <div className="container">
                    <div className="section_title">
                        <h2>3 Comments</h2>
                    </div>
                    <ul>
                        <li>
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={Blog01} alt="image" />
                                </div>
                                <div className="text">
                                    <span>30 min ago</span>
                                    <h4>Dolly Shell</h4>
                                </div>
                            </div>
                            <div className="comment">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the in
                                    dustrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen. </p>
                            </div>
                        </li>
                        <li className="replay_comment">
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={Blog01} alt="image" />
                                </div>
                                <div className="text">
                                    <span>15 min ago</span>
                                    <h4>Devil Joe</h4>
                                </div>
                            </div>
                            <div className="comment">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the industrys standard dummy text ev er since the when.</p>
                            </div>
                        </li>
                        <li>
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={Blog03} alt="image" />
                                </div>
                                <div className="text">
                                    <span>2 days ago</span>
                                    <h4>Sherly Shie</h4>
                                </div>
                            </div>
                            <div className="comment">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the in
                                    dustrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen. </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
    </>
  )
}

export default Comments