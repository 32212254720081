import React from 'react'

function Leave() {
    return (
        <>
            <section className="row_am comment_form_section">
                <div className="container">
                    <div className="section_title">
                        <h2>Leave a <span>comment</span></h2>
                        <p>Your email address will not be published. Required fields are marked *</p>
                    </div>
                    <form action="">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Name *" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder="Email *" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Phone" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Website  " />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <textarea className="form-control" placeholder="Comments"></textarea>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <button className="btn puprple_btn" type="submit">POST COMMENTS</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default Leave