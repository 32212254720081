import React, { useState } from 'react'
import Sectionbg from "../../../assets/images/section-bg.png"

const Main = ({ gredient }) => {

    const [activeFaq, setActiveFaq] = useState({
        a: true,
        b: false,
        c: false,
        d: false,
        e: false,
        f: false,
        g: false,
        h: false
    });

    const toggleFaq = (faq) => {
        setActiveFaq(prevState => {
            const newState = {};
            Object.keys(prevState).forEach(key => {
                newState[key] = key === faq ? !prevState[key] : false;
            });
            return newState;
        });
    };


    return (
        <>
            <section className="row_am faq_section">
                {gredient && <div className="faq_bg"> <img src={Sectionbg} alt="image" /> </div>}
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                        <h2><span>FAQ</span> - Frequently Asked Questions</h2>
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br /> indus orem Ipsum has beenthe
                            standard dummy.</p> */}
                    </div>
                    <div className="faq_panel">
                        <div className="accordion" id="accordionExample">

                            <div className="card" data-aos="fade-up">
                                <div className="card-header" id="headingOne">
                                    <h2 className="mb-0">
                                        <button type="button" className={`btn btn-link ${activeFaq.a ? "" : "collapsed"}`} onClick={() => toggleFaq('a')} data-toggle="collapse" data-target="#collapseOne">
                                            {activeFaq.a ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}What is Zzerb and how does it work?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseOne" className={`collapse ${activeFaq.a ? "show" : ""}`} aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>Zzerb is an innovative app designed to connect users with cannabis social clubs in Germany. It allows users to discover, join, and engage with cannabis social clubs in their area. By creating a profile on Zzerb, you can search for clubs, learn about their events and activities. You can also pick the club you like and join them if there are places still available.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card" data-aos="fade-up">
                                <div className="card-header" id="headingOne">
                                    <h2 className="mb-0">
                                        <button type="button" className={`btn btn-link ${activeFaq.b ? "" : "collapsed"}`} onClick={() => toggleFaq('b')} data-toggle="collapse" data-target="#collapseOne">
                                            {activeFaq.b ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}Can I place orders on Zzerb?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseOne" className={`collapse ${activeFaq.b ? "show" : ""}`} aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>Yes, you can place orders on Zzerb, but you can only pick up your order from the club you have selected and placed your order with. This ensures that the whole process  complies  with Germany’s laws and regulations.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card" data-aos="fade-up">
                                <div className="card-header" id="headingTwo">
                                    <h2 className="mb-0">
                                        <button type="button" className={`btn btn-link ${activeFaq.c ? "" : "collapsed"}`} onClick={() => toggleFaq('c')} data-toggle="collapse" data-target="#collapseTwo">
                                            {activeFaq.c ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}What information do I need to provide to register on Zzerb?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseTwo" className={`collapse ${activeFaq.c ? "show" : ""}`} aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>To register on Zzerb, you need to provide basic personal information such as your name, age, and email address. Depending on the club's requirements, you might also need to verify your identity with a government-issued ID to ensure you meet the legal age requirements for cannabis consumption.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card" data-aos="fade-up">
                                <div className="card-header" id="headingThree">
                                    <h2 className="mb-0">
                                        <button type="button" className={`btn btn-link  ${activeFaq.d ? "" : "collapsed"}`} onClick={() => toggleFaq('d')} data-toggle="collapse" data-target="#collapseThree">
                                            {activeFaq.d ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}Are my personal details and data safe on Zzerb?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseThree" className={`collapse ${activeFaq.d ? "show" : ""}`} aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>Yes, your personal details and data are safe on Zzerb. We prioritize user privacy and employ advanced encryption methods to protect your information. Our privacy policy outlines how we handle and safeguard your data. And don’t worry, the data is kept in Geramny 😊.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card" data-aos="fade-up" >
                                <div className="card-header" id="headingFour">
                                    <h2 className="mb-0">
                                        <button type="button" className={`btn btn-link  ${activeFaq.e ? "" : "collapsed"}`} onClick={() => toggleFaq('e')} data-toggle="collapse" data-target="#collapseFour">
                                            {activeFaq.e ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}What should I do if I encounter issues or have questions while using Zzerb?
                                        </button>
                                    </h2>
                                </div>

                                <div id="collapseFour" className={`collapse ${activeFaq.e ? "show" : ""}`} aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>If you encounter any issues or have questions while using Zzerb, you can contact our support team directly through the app or via email at support@zzerb.com for assistance.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card" data-aos="fade-up" >
                                <div className="card-header" id="headingFour">
                                    <h2 className="mb-0">
                                        <button type="button" className={`btn btn-link  ${activeFaq.f ? "" : "collapsed"}`} onClick={() => toggleFaq('f')} data-toggle="collapse" data-target="#collapseFour">
                                            {activeFaq.f ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}How to generate invoices for your club through Zzerb?
                                        </button>
                                    </h2>
                                </div>

                                <div id="collapseFour" className={`collapse ${activeFaq.f ? "show" : ""}`} aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>Zzerb includes an invoice generator feature in the Club's Admin Dashboard that allows you to create and send invoices for membership fees, orders, and other transactions, streamlining your financial management.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card" data-aos="fade-up" >
                                <div className="card-header" id="headingFour">
                                    <h2 className="mb-0">
                                        <button type="button" className={`btn btn-link  ${activeFaq.g ? "" : "collapsed"}`} onClick={() => toggleFaq('g')} data-toggle="collapse" data-target="#collapseFour">
                                            {activeFaq.g ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}How to accept or decline membership requests?
                                        </button>
                                    </h2>
                                </div>

                                <div id="collapseFour" className={`collapse ${activeFaq.g ? "show" : ""}`} aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>You can accept or decline membership requests in the 'Members' section of the Admin Dashboard. Each request will have options to approve or reject, and users will be notified of your decision through the app.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main