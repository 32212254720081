import React from 'react'
import img1 from '../../../assets/images/sticker.png'
import img2 from '../../../assets/images/filters.png'
// import img3 from '../../../assets/images/modern02.png'
import img3 from '../../../assets/images/coming-soon-strain.png'
import img4 from '../../../assets/images/modern03.png'
import Sectionbg from "../../../assets/images/section-bg.png"

const Main = ({ gredient }) => {

    const designBlocks = [
        {
            title: '3 Months Free',
            content: 'We cover the costs so you can explore the app and share your feedback with us.'
        },
        {
            title: 'Early Access',
            content: 'Be the first to try new updates before anyone else.'
        },
        {
            title: "Quarterly Meetings",
            content: 'We value your input and want to understand your needs to keep improving the app.'
        },
        {
            title: "Zzerb Point Here",
            content: 'Exclusive stickers that help your club stand out without breaking any laws.'
        }
    ];

    return (
        <>
            <section className={`row_am modern_ui_section ${gredient && "gredient-bg"}`} >
                {gredient &&
                    <div className="modernui_section_bg modernui-gredient"> <img src={Sectionbg} alt="image" /> </div>}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="ui_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100" >
                                    <h2>Zzerb <span>Society</span></h2>
                                    <p>
                                    We’re not just another app—<b>we’re your partner</b>. Join our Zzerb Society and be one of the <b>first 50 clubs</b> to help us build the best platform for CSCs. 
                                    We’re committed to creating a <b>win-win deal</b> that truly delivers <b>value FAR BEYOND the costs</b>. Your feedback drives our development, and <b>your success is our success, literally.</b>
                                    </p>
                                </div>
                                <ul className="design_block">
                                    {designBlocks.map((block, index) => (
                                        <li key={index} data-aos="fade-up" data-aos-duration="1500">
                                            <h4>{block.title}</h4>
                                            <p>{block.content}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                                <div className="left_img">
                                    <img className="moving_position_animatin" src={img1} alt="image" />
                                </div>
                                <div className="right_img">
                                    {[img3].map((img, index) => (
                                        <img key={index} className="moving_position_animatin" src={img} alt="image" />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main
