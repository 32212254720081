import React from 'react'
import { Link } from 'react-router-dom'
import Banner1 from "../../assets/images/banner-shape1.png"
import Banner2 from "../../assets/images/banner-shape2.png"
import Banner3 from "../../assets/images/banner-shape3.png"
import Shape1 from "../../assets/images/banner-shape-one1.png"
import Shape2 from "../../assets/images/banner-shape2two.png"
import Shape3 from "../../assets/images/banner-shapethree3.png"
import Shapev from "../../assets/images/banner-shapev.png"
import Shapev2 from "../../assets/images/banner-shapev2.png"
import Shapev3 from "../../assets/images/banner-shapev3.png"

const Main = ({ title, tag, bgimg, paragraph, no }) => {

    return (

        <>
            {no === 1 &&
                <div className="bred_crumb" style={{ backgroundImage: `url(${bgimg})` }}>
                    <div className="container">
                        <span className="banner_shape1"> <img src={Banner1} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Banner2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Banner3} alt="image" /> </span>

                        <div className="bred_text">
                            <h1>{title}</h1>
                            <p>{paragraph}</p>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>»</span></li>
                                <li><Link to="/about">{tag}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            }

            {no === 2 &&
                <div className="bred_crumb" style={{ backgroundImage: `url(${bgimg})` }}>
                    <div className="container">
                        <span className="banner_shape1"> <img src={Shape1} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Shape2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Shape3} alt="image" /> </span>

                        <div className="bred_text">
                            <h1>{title}</h1>
                            <p>{paragraph}</p>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>»</span></li>
                                <li><Link to="/about">{tag}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            }

            {no === 3 &&
                <div className="bred_crumb gredient-bg">
                    <div className="container">
                        <span className="banner_shape1"> <img src={Shapev} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Shapev2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Shapev3} alt="image" /> </span>
                        <div className="bred_text">
                            <h1>{title}</h1>
                            <p>{paragraph}</p>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>»</span></li>
                                <li><Link to="/about">{tag}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
            {no === 4 &&
                <div className="bred_crumb video" style={{ backgroundImage: `url(${bgimg})` }}>
                    <div className="container">
                        <span className="banner_shape1"> <img src={Banner1} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Banner2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Banner3} alt="image" /> </span>

                        <div className="bred_text">
                            <h1>{title}</h1>
                            <p>{paragraph}</p>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>»</span></li>
                                <li><Link to="/about">{tag}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
            {no === 5 &&
                <div className="bred_crumb" style={{ backgroundImage: `url(${bgimg})` }}>
                    <div className="container">
                        <span className="banner_shape1"> <img src={Banner1} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Banner2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Banner3} alt="image" /> </span>

                        <div className="bred_text">
                            <h1>{title}</h1>
                            <p>{paragraph}</p>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><span>»</span></li>
                                <li><Link to="/about">{tag}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default Main