import React from 'react'
import img1 from '../../../assets/images/fee.png'
import img2 from '../../../assets/images/refresh.png'
import img3 from '../../../assets/images/limit.png'
import img4 from '../../../assets/images/star.png'
import img5 from '../../../assets/images/features_frame.png'
import card from '../../../assets/images/card.png'
import Scureimg from "../../../assets/images/fee.png"
import Abtimg from "../../../assets/images/refresh.png"
import Communication from "../../../assets/images/communication.png"
import Supportimg from "../../../assets/images/star.png"
import Frameimg from "../../../assets/images/features_frame.png"

const Main = ({ video }) => {
    return (
        <>
            {video ?
                <section className="row_am features_section video-features" id="features">
                    <div className="container">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2><span>Transparent</span> Subscription Plans!</h2>
                            <p>Clear and straightforward membership options. <br /> Choose a plan that fits your needs: </p>
                        </div>
                        <div className="feature_detail">
                            <div className="left_data feature_box">
                                <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                                    <div className="icon">
                                        <img src={Scureimg} alt="image" />
                                    </div>
                                    <div className="text">
                                        <h4>Secure data</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and type setting indus ideas.</p>
                                    </div>
                                </div>
                                <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                                    <div className="icon">
                                        <img src={Abtimg} alt="image" />
                                    </div>
                                    <div className="text">
                                        <h4>Fully functional</h4>
                                        <p>Simply dummy text of the printing and typesetting indus lorem Ipsum is dummy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="right_data feature_box">
                                <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                                    <div className="icon">
                                        <img src={Communication} alt="image" />
                                    </div>
                                    <div className="text">
                                        <h4>Live chat</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and type setting indus ideas.</p>
                                    </div>
                                </div>
                                <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                                    <div className="icon">
                                        <img src={Supportimg} alt="image" />
                                    </div>
                                    <div className="text">
                                        <h4>24-7 Support</h4>
                                        <p>Simply dummy text of the printing and typesetting indus lorem Ipsum is dummy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                <img src={Frameimg} alt="image" />
                            </div>
                        </div>
                    </div>
                </section>
                :
                <section className="row_am features_section" id="features">
                    <div className="container">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2><span>Flexible</span> Subscription Plans!</h2>
                            <p>Your club is <b>one-of-a-kind</b>, and your <b>membership plans</b> should be too. <br/> We’re here to help you deliver a personalized experience that members <b>can’t find anywhere else.</b> </p>
                        </div>
                        <div className="feature_detail">
                            <div className="left_data feature_box">
                                <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                                    <div className="icon">
                                        <img src={img1} alt="image" />
                                    </div>
                                    <div className="text">
                                        <h4>Entry Fee</h4>
                                        <p>Set join fees that reflect your club’s value and attract the right members from the start.</p>
                                    </div>
                                </div>
                                <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                                    <div className="icon">
                                        <img src={img2} alt="image" />
                                    </div>
                                    <div className="text">
                                        <h4>Monthly Fee</h4>
                                        <p>Design subscription tiers that suit different members, ensuring everybody finds value.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="right_data feature_box">
                                <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                                    <div className="icon">
                                        <img src={img3} alt="image" />
                                    </div>
                                    <div className="text">
                                        <h4>Weed Limit</h4>
                                        <p>Manage consumption with monthly gram limits, keeping your inventory predictable and balanced.</p>
                                    </div>
                                </div>
                                <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                                    <div className="icon">
                                        <img src={img4} alt="image" />
                                    </div>
                                    <div className="text">
                                        <h4>Strains Available</h4>
                                        <p>Control strain access for each membership tier, adding a touch of exclusivity.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <img src={card} alt="image"/>
                </div> */}
                            <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                <img src={card} alt="image" style={{ position: 'absolute', left: 'auto', right: 'auto', top: '200px', borderRadius: 35, transform: 'scale(1.15)', }}/>
                                <img src={img5} alt="image" />
                            </div>
                        </div>
                    </div>
                </section>}
        </>
    )
}

export default Main