import React from 'react';
import { Link } from 'react-router-dom';

function Last() {
    const stories = [
        {
            imgSrc: "assets/images/story01.png",
            time: "45 min ago",
            title: "Cool features added!",
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has."
        },
        {
            imgSrc: "assets/images/story02.png",
            time: "45 min ago",
            title: "Top rated app! Yupp.",
            content: "Simply dummy text of the printing and typesetting industry lorem Ipsum has Lorem Ipsum is."
        },
        {
            imgSrc: "assets/images/story03.png",
            time: "45 min ago",
            title: "Creative ideas on app.",
            content: "Printing and typesetting industry lorem Ipsum has Lorem simply dummy text of the."
        },
        {
            imgSrc: "assets/images/story04.png",
            time: "45 min ago",
            title: "Excellence UI design",
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has."
        },
        {
            imgSrc: "assets/images/story05.png",
            time: "45 min ago",
            title: "Quick and easy Search",
            content: "Simply dummy text of the printing and typesetting industry lorem Ipsum has Lorem Ipsum is."
        },
        {
            imgSrc: "assets/images/story06.png",
            time: "45 min ago",
            title: "Chat function eded",
            content: "Printing and typesetting industry lorem Ipsum has Lorem simply dummy text of the."
        },
        {
            imgSrc: "assets/images/story07.png",
            time: "45 min ago",
            title: "Cool features added!",
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has."
        },
        {
            imgSrc: "assets/images/story08.png",
            time: "45 min ago",
            title: "Top rated app! Yupp.",
            content: "Simply dummy text of the printing and typesetting industry lorem Ipsum has Lorem Ipsum is."
        },
        {
            imgSrc: "assets/images/story09.png",
            time: "45 min ago",
            title: "Creative ideas on app.",
            content: "Printing and typesetting industry lorem Ipsum has Lorem simply dummy text of the."
        },
        {
            imgSrc: "assets/images/story01.png",
            time: "45 min ago",
            title: "Excellence UI design",
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has."
        },
        {
            imgSrc: "assets/images/story02.png",
            time: "45 min ago",
            title: "Quick and easy Search",
            content: "Simply dummy text of the printing and typesetting industry lorem Ipsum has Lorem Ipsum is."
        },
        {
            imgSrc: "assets/images/story03.png",
            time: "45 min ago",
            title: "Chat function eded",
            content: "Printing and typesetting industry lorem Ipsum has Lorem simply dummy text of the."
        }
    ];
    return (
        <>
            <section className="row_am latest_story blog_list_story" id="blog">
                <div className="container">
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                                    <div className="story_img">
                                        <img src={story.imgSrc} alt="image" />
                                        <span>{story.time}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.content}</p>
                                        <Link to="/blog-single">READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination_block">
                        <ul>
                            <li><Link to="#" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                            <li><Link to="#">1</Link></li>
                            <li><Link to="#" className="active">2</Link></li>
                            <li><Link to="#">3</Link></li>
                            <li><Link to="#">4</Link></li>
                            <li><Link to="#">5</Link></li>
                            <li><Link to="#">6</Link></li>
                            <li><Link to="#" className="next">Next <i className="icofont-arrow-right"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )}
    
export default Last