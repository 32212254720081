import React from 'react';
import { Link } from 'react-router-dom';

const stories = [
  {
    image: 'assets/images/story01.png',
    timeAgo: '45 min ago',
    title: 'Cool features added!',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has.',
    link: '#',
  },
  {
    image: 'assets/images/story02.png',
    timeAgo: '45 min ago',
    title: 'Top rated app! Yupp.',
    text: 'Simply dummy text of the printing and typesetting industry lorem Ipsum has Lorem Ipsum is.',
    link: '#',
  },
  {
    image: 'assets/images/story03.png',
    timeAgo: '45 min ago',
    title: 'Creative ideas on app.',
    text: 'Printing and typesetting industry lorem Ipsum has Lorem simply dummy text of the.',
    link: '#',
  },
];

function Latest() {
  return (
    <section className="row_am latest_story" id="blog">
      <div className="container">
        <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
          <h2>
            Read latest <span>story</span>
          </h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typese tting <br /> indus orem Ipsum has beenthe standard
            dummy.
          </p>
        </div>
        <div className="row">
          {stories.map((story, index) => (
            <div className="col-md-4" key={index}>
              <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                <div className="story_img">
                  <img src={story.image} alt="image" />
                  <span>{story.timeAgo}</span>
                </div>
                <div className="story_text">
                  <h3>{story.title}</h3>
                  <p>{story.text}</p>
                  <Link to={story.link}>READ MORE</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Latest;